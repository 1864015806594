.header {
  text-align: center;
  background-color: #f8f9fa;
  padding-bottom: 40px;
}

.image-container {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.profile-pic {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title Section */
.profile-title-container {
  margin-top: 30px;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  display: inline-block;
  max-width: 600px; /* Limit width for larger screens */
  transition: transform 0.3s ease;
}

.profile-title-container:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.profile-title {
  margin-bottom: 10px;
}

.profile-title h2 {
  font-size: 1.6rem;
  color: #0d47a1; /* Strong blue color */
  margin: 0;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.profile-title h2 span {
  color: #1976d2; /* Darker ISKCON blue */
  font-weight: 800;
}

.profile-subtitle h3 {
  font-size: 1.5rem;
  color: #424242; /* Dark gray for contrast */
  margin: 0;
  font-weight: 600;
}

.profile-subtitle h3 span {
  color: #d32f2f; /* Accent color for Maharashtra */
  font-weight: 700;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-title-container {
    padding: 20px;
  }

  .profile-title h2 {
    font-size: 1.4rem;
  }

  .profile-subtitle h3 {
    font-size: 1.2rem;
  }
}
