.roles {
    padding: 50px 20px;
    background-color: #f1f1f1;
    text-align: center;
  }
  
  .roles h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .role-card {
    background-color: #fff;
    padding: 20px;
    margin: 20px auto;
    max-width: 700px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .role-card h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .role-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  