.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .footer a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  