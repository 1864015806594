.servant-section {
    background-color: #f0f0f0;
    padding: 40px 20px;
    text-align: center;
  }
  
  .servant-section .title h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .carousel-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .carousel-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  